@font-face {
    font-family: 'Felt Tip Woman';
    src: url('assets/fonts/Felt-Tip-Woman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* App */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Header */

header {
    background-image: url('https://cdn.prod.website-files.com/65ccdb9b3a0994655a108f14/6674a915a59abd7bcc81f3b6_Untitled%20design%20(16).jpg');
    min-height: 100vh;
    color: white;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header-text-wrapper {
    padding: calc(20px + 2vmin);
    max-width: 1000px;
}

.Header-button-wrapper {
    padding: calc(20px + 2vmin);
}

.Header-title {
    font-family: 'Felt Tip Woman', sans-serif;
    color: #bb725e;
    font-size: calc(50px + 2vmin);
    font-weight: bold;
}

.Header-text {
    font-family: 'Felt Tip Woman', sans-serif;
    color: #ffffff;
    font-size: calc(15px + 2vmin);
    text-align: left;
}

.Header-button {
    width: calc(270px + 2vmin);
    height: calc(80px + 2vmin);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-family: 'Felt Tip Woman', sans-serif;
    color: #ffffff;
    font-size: calc(15px + 2vmin);
    text-align: left;
    background-color: #bb725e;
}


.PP {
    width: calc(370px + 2vmin);
    height: calc(370px + 2vmin);
    border-radius: 20%;
    padding: calc(20px + 2vmin);
}

/* Body */

body {
    background-color: #202020;
}

.Body {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('https://cdn.prod.website-files.com/65ccdb9b3a0994655a108f14/65ccfc2c96936cb597f8c29f_PESO%20PLUMA%20-%20MOCKUP%20(2).png');
}

.Body-text-wrapper {
    padding: calc(20px + 2vmin);
    max-width: 1000px;
}


/* Footer */

footer {
    padding: 20px;
}

.Footer-card {
    border: 0 solid gray;
    border-bottom-width: 2px;
    text-align: left;
    width: 100%;
}

.Footer-text-wrapper {
    padding: 20px;
}

/* Banner */

.Banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #202020;
}

.Banner-wrapper {
    width: 100%;
    height: 65px;
    background-color: #202020;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.SolanaLogo {
    width: 50px;
    height: 50px;
}

.Avatar {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid #ffffff;
    margin: 10px;
}

.Banner-text {
    font-family: 'Felt Tip Woman', sans-serif;
    color: #ffffff;
    font-size: calc(15px + 2vmin);
    margin: 10px;
}

/* CountDown */

.CountDown {
    overflow-x: hidden;
}

.CountDown-text {
    position: absolute;
    text-align: center;
    width: 100%
}

.subtitle {
    font-size: calc(30px + 2vmin);
    font-weight: bold;
    font-family: 'Felt Tip Woman', sans-serif;
    color: #ffffff;
}

/* VideoGrid */

.VideoGrid {
    display: flex;
    width: 10%;
    height: 10%;
}

/* General */

.content {
    padding-top: 60px;
}

.Flex {
    display: flex;
    align-items: center;
}

@media (max-width: 1000px) {
    .Header {
        display: block;
    }
    .Header-title {
        font-size: calc(40px + 2vmin);
    }
    .Header-text-wrapper {
        padding: 0;
        margin-left: 10px;
        margin-right: 10px;
    }
    .Header-button-wrapper {
        padding: 0;
        display: flex;
        justify-content: center;
    }
    .PP {
        width: calc(300px + 2vmin);
        height: calc(300px + 2vmin);
        margin-top: 10px;
        padding: 0;
        align-self: center;
    }
}

@media (max-width: 380px) {
    .Banner-text {
        font-size: calc(7px + 2vmin);
        margin: 5px;
    }
    .Header-title {
        font-size: calc(30px + 2vmin);
    }
    .Header-text {
        font-family: 'Felt Tip Woman', sans-serif;
        color: #ffffff;
        font-size: calc(10px + 2vmin);
        text-align: left;
    }
    .PP {
        width: calc(200px + 2vmin);
        height: calc(200px + 2vmin);
        margin-top: 10px;
        padding: 0;
        align-self: center;
    }
}

@media (max-width: 460px) {
    .Banner-text {
        font-size: calc(10px + 2vmin);
        margin: 5px;
    }
}

@media (max-height: 680px) {
    .PP {
        width: calc(100px + 2vmin);
        height: calc(100px + 2vmin);
        margin-top: 10px;
        padding: 0;
        align-self: center;
    }
}

@media (max-height: 780px) {
    .PP {
        width: calc(160px + 2vmin);
        height: calc(160px + 2vmin);
        margin-top: 10px;
        padding: 0;
        align-self: center;
    }
}
